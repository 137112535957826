import { computed, ComputedRef, inject, provide, Ref, ref, watch } from 'vue';
import {
  LogiSystemDataMetricsQueryParameter,
  LogiSystemDataQueryParameterValue,
} from 'src/models/new/Metrics/BasicMetrics/logiSystemDataMetrics';
import { useDataSources } from 'src/composables/asyncResources/useDataSources';
import { RecordId } from 'src/util/recordId';
import {
  useDataSourceParameterInputFormAdjustment,
  UseDataSourceParameterInputFormAdjustmentResult,
} from './useDataSourceParameterInputFormAdjustment';
import {
  useDataSourceParameterInputValidation,
  UseDataSourceParameterInputValidationResult,
} from './useDataSourceParameterInputValidation';
import { LogiCoredataBudgetGroup } from 'src/models/new/logiCoredataBudgetGroup';

type DataSourceParameterInput = {
  parameterId: number | null;
  values: LogiSystemDataQueryParameterValue[];
};

type InjectionValue = {
  inputs: Ref<DataSourceParameterInput[]>;
  selectedKeyId: Ref<RecordId | null>;
  logiCoredataBudgetGroup: Ref<LogiCoredataBudgetGroup | null>;
  isLogiCoredataBudgetGroupBound: Ref<boolean>;
};

export type UseDataSourceParameterInputResult = {
  inputs: Ref<DataSourceParameterInput[]>;
  fieldNames: UseDataSourceParameterInputValidationResult['fieldNames'];
  selectedKeyId: Ref<RecordId | null>;
  validationResult: UseDataSourceParameterInputValidationResult['validationResult'];
  bindLogiCoredataBudgetGroup: (boundValue: Ref<LogiCoredataBudgetGroup | null>) => void;
  initialize: UseDataSourceParameterInputFormAdjustmentResult['initialize'];
  clear: UseDataSourceParameterInputFormAdjustmentResult['clear'];
  pop: UseDataSourceParameterInputFormAdjustmentResult['pop'];
  push: UseDataSourceParameterInputFormAdjustmentResult['push'];
  reflect: () => LogiSystemDataMetricsQueryParameter[];
  selectKeyId: (dataSourceKeyId: number | null) => void;
  validateEachField: UseDataSourceParameterInputValidationResult['validateEachField'];
  validateCombinedCondition: UseDataSourceParameterInputValidationResult['validateCombinedCondition'];
  ensureValidation: UseDataSourceParameterInputValidationResult['ensureValidation'];
  onSelectDataSourceParameter: (fieldIndex: number) => void;
  optionsByFieldIndex: ComputedRef<Record<number, { value: number; label: string }[]>>;
  isFreeInputParameter: (fieldIndex: number) => boolean;
};

const key = Symbol('dataSourceParameterInput');

export const useDataSourceParameterInput = () => {
  const injected = inject<InjectionValue | null>(key, null);

  const inputs = injected ? injected.inputs : ref<DataSourceParameterInput[]>([]);
  const selectedKeyId = injected ? injected.selectedKeyId : ref<RecordId | null>(null);
  const logiCoredataBudgetGroup = injected
    ? injected.logiCoredataBudgetGroup
    : ref<LogiCoredataBudgetGroup | null>(null);
  const isLogiCoredataBudgetGroupBound = injected ? injected.isLogiCoredataBudgetGroupBound : ref(false);

  if (injected === null) {
    provide(key, {
      inputs,
      selectedKeyId,
      logiCoredataBudgetGroup,
      isLogiCoredataBudgetGroupBound,
    });
  }

  const bindLogiCoredataBudgetGroup = (boundValue: Ref<LogiCoredataBudgetGroup | null>) => {
    logiCoredataBudgetGroup.value = boundValue.value;
    isLogiCoredataBudgetGroupBound.value = true;
    watch(boundValue, (newValue) => {
      logiCoredataBudgetGroup.value = newValue;
    });
  };

  const warnIfNoLogiCoredataBudgetGroupBound = (): void => {
    if (!isLogiCoredataBudgetGroupBound.value) {
      console.error('bindLogiCoredataBudgetGroup で管理グループをバインドしてください。');
    }
  };

  const { dataSourceParametersRef } = useDataSources();

  const {
    initialize,
    setMaxLength,
    isFullLength,
    clear,
    pop: originalPop,
    push,
  } = useDataSourceParameterInputFormAdjustment({ inputs });

  const {
    fieldNames,
    validationResult,
    cleanUpEachFieldValidationErrors,
    validateEachField,
    validateCombinedCondition,
    ensureValidation,
  } = useDataSourceParameterInputValidation({ inputs });

  const pop = () => {
    cleanUpEachFieldValidationErrors(inputs.value.length - 1);
    originalPop();
  };

  const reflect = (): LogiSystemDataMetricsQueryParameter[] => {
    const dataSourceParameters: LogiSystemDataMetricsQueryParameter[] = [];
    inputs.value.forEach((dataSourceParameterInput) => {
      const dataSourceParametersForInput = dataSourceParameterInput.values.map((value) => {
        return {
          parameterId: dataSourceParameterInput.parameterId!,
          value: value,
        };
      });
      dataSourceParameters.push(...dataSourceParametersForInput);
    });

    return dataSourceParameters;
  };

  const selectKeyId = (dataSourceKeyId: number | null) => {
    selectedKeyId.value = dataSourceKeyId;
  };

  watch([dataSourceParametersRef, selectedKeyId], ([newParameters, newKeyId]) => {
    setMaxLength(newParameters.filter((el) => el.dataSourceKeyId === newKeyId).length);
  });

  const optionsByFieldIndex = computed<Record<number, { value: number; label: string }[]>>(() => {
    const keyId = selectedKeyId.value;
    if (keyId === null) {
      return {};
    }

    return inputs.value.map((_, fieldIndex) => {
      return dataSourceParametersRef.value
        .filter((dataSourceParameter) => {
          const reservedParameterIds = inputs.value
            .filter((_, index) => index !== fieldIndex)
            .map((el) => el.parameterId);

          return (
            dataSourceParameter.dataSourceKeyId === keyId && !reservedParameterIds.includes(dataSourceParameter.id)
          );
        })
        .map((dataSourceParameter) => {
          return {
            value: dataSourceParameter.id,
            label: dataSourceParameter.disp_name,
          };
        });
    });
  });

  const { prepareDataSourceParameterValues } = useDataSources();

  const clearValues = (fieldIndex: number) => {
    const input = inputs.value[fieldIndex];
    if (input !== undefined) {
      input.values = [];
    }
  };

  const loadDataSourceParameterValues = async (dataSourceParameterId: number) => {
    warnIfNoLogiCoredataBudgetGroupBound();
    if (logiCoredataBudgetGroup.value === null) {
      return;
    }

    await prepareDataSourceParameterValues(logiCoredataBudgetGroup.value, dataSourceParameterId);
  };

  const onSelectDataSourceParameter = (fieldIndex: number): void => {
    cleanUpEachFieldValidationErrors(fieldIndex);
    clearValues(fieldIndex);

    const dataSourceParameterId = inputs.value[fieldIndex]?.parameterId;
    if (dataSourceParameterId === undefined || dataSourceParameterId === null) {
      return;
    }

    loadDataSourceParameterValues(dataSourceParameterId);
  };

  const isFreeInputParameter = (fieldIndex: number): boolean => {
    const parameterId = inputs.value[fieldIndex]?.parameterId;
    return dataSourceParametersRef.value.find((el) => el.id === parameterId)?.is_free_input ?? false;
  };

  return {
    inputs,
    fieldNames,
    selectedKeyId,
    validationResult,
    bindLogiCoredataBudgetGroup,
    initialize,
    isFullLength,
    clear,
    pop,
    push,
    reflect,
    selectKeyId,
    validateEachField,
    validateCombinedCondition,
    ensureValidation,
    onSelectDataSourceParameter,
    optionsByFieldIndex,
    isFreeInputParameter,
  };
};
