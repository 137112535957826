import userApi from 'src/apis/user';
import { getPromiseOfGetMe, setPromiseOfGetMe } from 'src/store/dataFetchCaches/promiseOfGetMe';

const SET_LOGIN_USER = 'SET_LOGIN_USER';
const CLEAR_LOGIN_USER = 'CLEAR_LOGIN_USER';

const _initialState = {
  id: -1,
  email: '',
  family_name: '',
  first_name: '',
  job_title: '',
  role: '',
  has_role_super_admin: false,
  has_role_admin: false,
  has_role_member: false,
  is_super_admin: false,
  is_admin: false,
  is_member: false,
  env: '',
  logi_coredata_url: '',
  has_metrics_admin_role: false,
  has_metrics_gt_manager_role: false,
  has_metrics_gt_read_write_role: false,
  has_report_admin_role: false,
  has_report_gt_manager_role: false,
};

const state = structuredClone(_initialState);

const getters = {
  isLoggedIn: (state) => state.id !== -1,
  id: (state) => state.id,
  email: (state) => state.email,
  fullName: (state) => `${state.family_name} ${state.first_name}`,
  env: (state) => state.env,
  logiCoredataUrl: (state) => state.logi_coredata_url,
  hasRoleSuperAdmin: (state) => state.has_role_super_admin,
  hasMetricsAdminRole: (state) => state.has_metrics_admin_role,
  hasMetricsGtManagerRole: (state) => state.has_metrics_gt_manager_role,
  hasMetricsGtReadWriteRole: (state) => state.has_metrics_gt_read_write_role,
  hasReportAdminRole: (state) => state.has_report_admin_role,
  hasReportGtManagerRole: (state) => state.has_report_gt_manager_role,
};

const actions = {
  getMe({ commit, state, getters }) {
    if (getters.isLoggedIn) {
      return Promise.resolve(Object.assign({}, state));
    }
    if (!getPromiseOfGetMe()) {
      setPromiseOfGetMe(userApi.getMe());
    }
    return getPromiseOfGetMe().then(({ data }) => {
      commit(SET_LOGIN_USER, { data });
      return Object.assign({}, state);
    });
  },
  getMeRefresh({ commit, state }) {
    return userApi.getMe().then(({ data }) => {
      commit(SET_LOGIN_USER, { data });
      return Object.assign({}, state);
    });
  },
  setMe({ commit }, data) {
    commit(SET_LOGIN_USER, { data });
  },
  login({ commit, state }, data) {
    return userApi.login(data).then((res) => {
      const token = res.headers.authorization.split(' ')[1];
      commit(SET_LOGIN_USER, { data: res.data });
      return { access_token: token };
    });
  },
  logout({ commit }) {
    return userApi.logout().then(() => {
      commit(CLEAR_LOGIN_USER);
    });
  },
};

const mutations = {
  [SET_LOGIN_USER](state, { data }) {
    Object.keys(data).forEach((k) => {
      state[k] = data[k];
    });
  },
  [CLEAR_LOGIN_USER](state) {
    const initialState = JSON.parse(JSON.stringify(_initialState));
    Object.keys(initialState).forEach((k) => {
      state[k] = initialState[k];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
