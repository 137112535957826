import { Report } from 'src/models/new/report';
import { RecordId, isRecordIdValid } from 'src/util/recordId';
import { buildUrl, extractUrlHash, extractUrlQueryString } from 'src/util/url';

export const SCHEME_REPORT = 'reports://';
const PATH_TEMPLATE_REPORT = '/reports/:id';

export const reportToCustomUrl = (report: Report, queryString: string = '', urlHash: string = ''): string => {
  return buildUrl(SCHEME_REPORT, report.id, queryString, urlHash);
};

// 以前はURLクラスのpathnameを使っていたが、pathnameと認識されていた部分がhostになったりクライアントの仕様が安定しないので直接的に処理する
const dropSchema = (url: string) => url.replace(/^[^:/]+:\/\//, '');

export const normalizeReportSchemeUrl = (url: string): string => {
  const parsedUrl = new URL(url);
  const id = dropSchema(url);
  const queryString = extractUrlQueryString(parsedUrl);
  const urlHash = extractUrlHash(parsedUrl);
  // スキーマを外した部分がidのみである事を想定しており、そうでない場合はレポート画面のURLではなくなってしまう
  return (
    location.origin +
    PATH_TEMPLATE_REPORT.replace(':id', id) +
    (queryString ? '?' + queryString : '') +
    (urlHash ? '#' + urlHash : '')
  );
};

export const customUrlToReportId = (url: string): RecordId | null => {
  if (!url.startsWith(SCHEME_REPORT)) {
    return null;
  }
  const mainPart = dropSchema(url);

  if (!mainPart.match(/^\d+$/)) {
    return null;
  }

  const id = Number(mainPart.replace(/^\//g, ''));
  // 数字部分があまりに大きくJSのNumber型の範囲を越える場合はInfinityになりnullが返る
  // あまりに大きな場合はバックエンドにもidとして存在しないので普通起きないが、条件分岐を考慮する際注意
  return isRecordIdValid(id) ? id : null;
};

export const isUrlCustomReportUrl = (url: string): boolean => {
  return customUrlToReportId(url) !== null;
};
